import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';
import '../styles/innerpage.scss';

function About() {
  gsap.registerPlugin(ScrollTrigger);

  const tl = useRef();
  const missionParent = useRef();

  useEffect(() => {
    gsap.set('.floating-mission-med-1, .floating-mission-med-2', {
      y: 0,
      scale: 1,
    });

    tl.current = gsap.timeline().to('.floating-mission-med-1', {
      scrollTrigger: {
        start: '10% 40%',
        end: '100% 30%',
        trigger: missionParent.current,
        // markers: true,
        scrub: 0.5,
      },

      y: 320,
    });

    tl.current = gsap.timeline().to('.floating-mission-med-2', {
      scrollTrigger: {
        start: '10% 40%',
        end: '100% 30%',
        trigger: missionParent.current,
        // markers: true,
        scrub: 0.5,
      },

      y: -320,
    });
  }, []);

  return (
    <Layout>
      <SEO title="About Us " />
      <section className="inner-hero bg-lightgreen">
        <div className="container">
          <div className="inner-hero-content text-center">
            <h1 className="text-primary font-medium mb-2">About Us</h1>
            <p className="text-18 md:text-24">
              We believe in change, change for the betterment of society.
            </p>
          </div>
        </div>
      </section>

      <section className="section-gap">
        <div className="container">
          <div className="row">
            <div className="col-md col-8 offset-2">
              <div className="text-center">
                <p className="text-18">
                  Pharmacy services in India have been practiced in a very
                  traditional and conventional way. The approach to providing
                  services needed a shift to make it more patient-centric. We
                  saw an opportunity. We came up with a solution! We started
                  with EFFIPAC to provide patient-centric care to the community
                  which will enhance their quality of life.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-gap relative !pb-0" id="about-effipac">
        <div className="section-pad relative !pt-0 md:!pt-[inherit]">
          <div className="container">
            <div className="row items-center">
              <div className="col col-6">
                <div className="md:py-4">
                  <h2 className="heading mb-1">Our Vision</h2>
                  <p className="italic text-orange mb-4">
                    We believe in the pursuit of doing good!
                  </p>
                  <p className="max-w-[450px]">
                    We believe that we can and should have a positive impact on
                    the communities that we serve - one person, one
                    prescription, and one neighborhood at a time. We aim to work
                    together with our customers, our champion doctors and
                    pharmacists, and partners to bring positive change.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="md:absolute right-0 top-0 bottom-0 md:w-1/2 h-[280px] md:h-full mt-3 md:mt-0">
            <iframe
              className="w-full h-full md:object-cover"
              src="https://www.youtube.com/embed/I7gxtQgP6ec"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen></iframe>
          </div>
        </div>
      </section>
      <section className="section-gap  !pt-0">
        <div className="section-pad bg-lightgreen relative" ref={missionParent}>
          <div className="floating-mission-med floating-mission-med-1 absolute -top-4 left-3 hidden lg:block">
            <img src="/img/med-bottom-right.png" alt="" />
          </div>
          <div className="floating-mission-med floating-mission-med-2 absolute -bottom-4 right-3 hidden lg:block">
            <img src="/img/med-left-center.png" alt="" />
          </div>
          <div className="container">
            <div className="text-center">
              <h2 className="heading mb-2">Our Mission</h2>

              <p className="max-w-[690px] mx-auto">
                The majority of the population in our country has problems
                related to medicine consumption, leading to complications in the
                treatment. We aim to reduce the errors due to polypharmacy and
                increase the medication adherence of patients.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section-gap">
        <div className="container">
          <div className="row items-center">
            <div className="col col-6">
              <div className="mb-4 md:mb-0">
                <img src="/img/neeraj.jpg" alt="neeraj" />
              </div>
            </div>

            <div className="col col-5 offset-1">
              <h2 className="heading mb-3 ">Meet our founder</h2>

              <h3 className="heading-small mb-3 text-orange">
                Dr. Niraj Bafna
              </h3>

              <p>
                Niraj is a Doctor of Pharmacy graduate who further studied
                healthcare innovation at MIT and Harvard Medical School, Boston.
                He has always been passionate about solving problems. He
                identified the problem of ‘Medication Non-adherence’ amongst
                patients while he spent time at his father’s pharmacy and also
                during his time at a tertiary care hospital. There was no
                curated solution to solve this problem. That's where he stood up
                to solve this problem of medication mismanagement and came up
                with the idea of EFFIPAC.
              </p>

              <p>
                He is extremely keen on building consumer-focused,
                patient-centric solutions. He loves to brainstorm diverse ideas
                and insights and likes to engage with students by arranging
                design thinking workshops for them. He is humorous, great at
                visualization, extrovert by nature, and introvert by heart.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
export default About;
